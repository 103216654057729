import React, { useState } from "react";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import { Input, Button, AlertModal } from "../components/common";

import Auth from "../components/auth";

import { useAuth } from "../hooks";

function LoginPage() {
  const [state, setState] = useState({
    email: "",
    password: "",
    identifier: "",
    error: false,
    succes: false,
    message: "",
    validation: {
      email: true,
      password: true,
    },
  });

  const { login } = useAuth();

  const handleSubmit = async () => {
    const { email, password } = state;
    try {
      const isLoggedIn = await login({ identifier: email, password });

      if (isLoggedIn) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    } catch (e) {
      const message =
        e?.response?.data?.error?.message || "Something went wrong!";
      setState({ ...state, succes: false, error: true, message });
      //   const {
      //     response: {
      //       data: {
      //         message: [
      //           {
      //             messages: [error],
      //           },
      //         ],
      //       },
      //     },
      //   } = e;
      //   const { message: msg } = error;
      //   setError(msg);
    }
  };

  const onValidate = (name, status) => {
    setState({ ...state, validation: { ...state.validation, [name]: status } });
  };

  return (
    <>
      <AlertModal
        type={state.error ? "error" : "success"}
        show={state.error}
        title={state.message}
        onClose={() =>
          setState({ ...state, succes: false, error: false, message: false })
        }
      />
      <Auth
        title="Login"
        customNav={
          <div className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4 hidden md:block">
            New purchaser?{" "}
            <Link className="text-brand-primary" to="/create-account">
              Create an account!
            </Link>
          </div>
        }
        headingTitle="Welcome Back!"
        headingDesc="Login to access your purchases"
        onSubmit={handleSubmit}
        mobileNote={
          <>
            New customer? <Link to="/create-account">Signup!</Link>
          </>
        }
      >
        <>
          <Input
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            name="email"
            label="Email address"
            placeholder="email@example.com"
            value={state.email}
            onChange={(email) => setState({ ...state, email })}
            onValidate={onValidate}
          />
          <Input
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            type="password"
            name="password"
            label="Password"
            showForgotLink
            value={state.password}
            placeholder="Enter your password"
            onChange={(password) => setState({ ...state, password })}
            onValidate={onValidate}
          />
          <Button
            hasMinWidth={false}
            disabled={
              !state.email ||
              !state.password ||
              !state.validation.email ||
              !state.validation.password
            }
            icon="vg-log-in"
            text="Login"
          />
        </>
      </Auth>
    </>
  );
}

export default LoginPage;
